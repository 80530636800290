import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import animationData from 'animations/growth/profile/diagram.json';
import Scheme from 'components/shared/scheme';

const title =
  'Optimize advertising performance with Yobi’s Predictive Models powered by a complete representation of customer behavior';

const Profile = () => (
  <Scheme
    className="mt-32 md:mt-40"
    title={title}
    titleSize="md"
    animationData={animationData}
    withTabs
  />
);

export default Profile;
