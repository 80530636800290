import React from 'react';

import Features from 'components/pages/growth/features';
import OurScale from 'components/pages/growth/our-scale';
import Profile from 'components/pages/growth/profile';
import Quote from 'components/shared/quote';
import RequestDemoHero from 'components/shared/request-demo-hero';
import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const pageTitle = 'Growth';
const pageDescription =
  'Effortlessly create highly targeted audiences of prospective customers, across any marketing channel, to scale your business';

const quote = {
  title: `“We saw a <span class="text-primary-2">500% increase</span> in conversions from Yobi’s audience when compared to The Trade Desk”.`,
  caption: 'Head of Media & Analytics at a Media Company	',
};

const Growth = () => (
  <MainLayout pageMetadata={SeoMetadata.growth}>
    <RequestDemoHero title={pageTitle} description={pageDescription} />
    <Features />
    <Profile />
    <Quote title={quote.title} caption={quote.caption} />
    <OurScale />
  </MainLayout>
);

export default Growth;
