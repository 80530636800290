import React from 'react';

import CardWithIcon from 'components/shared/card-with-icon';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import AdaptiveModelsIcon from './images/adaptive-models.inline.svg';
import BehavioralDataIcon from './images/behavioral-data.inline.svg';
import PredictiveIcon from './images/predictive.inline.svg';

const title = 'Yobi Powers Privacy Centric Businesses';
const items = [
  {
    icon: BehavioralDataIcon,
    title: 'Behavioral Data',
    description:
      'Discover valuable connections between behavioral signals to optimize targeting across marketing channels',
  },
  {
    icon: AdaptiveModelsIcon,
    title: 'Adaptive Models',
    description: 'Algorithms that dynamically respond to changes in environment or behavior',
  },
  {
    icon: PredictiveIcon,
    title: 'Predictive',
    description:
      'Yobi’s advanced ML can predict certain products in an individual’s future shopping cart with 99% accuracy',
  },
];

const Features = () => (
  <section className="mt-24 lg:mt-32 xl:mt-40">
    <Container className="text-center">
      <Heading className="text-center" tag="h2" size="lg">
        {title}
      </Heading>

      <div className="grid grid-cols-1 gap-8 mt-10 lg:mt-16 lg:grid-cols-3">
        {items.map(({ icon: Icon, title, description }, index) => (
          <CardWithIcon title={title} description={description} icon={Icon} key={index} />
        ))}
      </div>
    </Container>
  </section>
);

export default Features;
