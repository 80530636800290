import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';

import DeviceIcon from './images/device.inline.svg';
import EmailIcon from './images/email.inline.svg';
import HouseIcon from './images/house.inline.svg';
import PeopleIcon from './images/people.inline.svg';

const title = 'Our US Scale';
const items = [
  {
    icon: PeopleIcon,
    title: '+ 300m',
    description: 'People',
  },
  {
    icon: DeviceIcon,
    title: '+ 600m',
    description: 'Devices',
  },
  {
    icon: HouseIcon,
    title: '+ 130m',
    description: 'Households',
  },
  {
    icon: EmailIcon,
    title: '+ 500m',
    description: 'Emails',
  },
];

const OurScale = () => (
  <section className="relative pt-20 pb-40 lg:pt-28 xl:pt-32 mt-28 lg:mt-32 xl:mt-40 -mb-44 gradient-background-gray after:absolute after:-bottom-96 after:gradient-background-gray after:w-full after:h-96">
    <Container>
      <Heading className="text-center" tag="h2" size="lg">
        {title}
      </Heading>

      <div className="grid grid-cols-1 mt-20 lg:mt-28 xl:mt-32 sm:grid-cols-2 gap-y-16 gap-x-8 lg:grid-cols-4">
        {items.map(({ icon: Icon, title, description }, index) => (
          <div
            className="relative flex flex-col items-center p-8 pt-16 text-left bg-white shadow-xl rounded-2xl"
            key={index}
          >
            <Icon className="absolute -top-7" />
            <Heading
              className="w-full pb-5 leading-none text-center border-b-2 lg:pb-8 border-gray-7"
              tag="h3"
              size="md"
            >
              <span className="text-transparent whitespace-nowrap bg-clip-text bg-gradient-to-r from-primary-1 to-primary-3">
                {title}
              </span>
            </Heading>
            <p className="mt-5 text-xl font-bold leading-none whitespace-nowrap">{description}</p>
          </div>
        ))}
      </div>
    </Container>
  </section>
);

export default OurScale;
